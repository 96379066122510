import React, { useRef } from "react";
import SEO from "../../components/seo/seo";
import { useStaticQuery, graphql } from "gatsby";
import { PageParams } from "../../../types";
import Img from "gatsby-image";

import "./mobile-aplications.scss";

import { InView } from "react-intersection-observer";

import NativeScript from "../../images/technologies/logo_nativescript.inline.svg";
import NodeJs from "../../images/technologies/logo_nodejs.inline.svg";
import LogoElectron from "../../images/technologies/logo_electron.inline.svg";
import Ionic from "../../images/technologies/logo_ionic.inline.svg";
import ReactLogo from "../../images/technologies/logo-react.inline.svg";
import Flutter from "../../images/technologies/logo-flutter.inline.svg";
import GooglePlaySVG from "../../images/svg/google_play.inline.svg";
import AppStoreSVG from "../../images/svg/app_store.inline.svg";
import AndroidRobot from "../../images/svg/android_robot.inline.svg";
import AppleLogo from "../../images/svg/apple_logo.inline.svg";
import Administration from "../../images/svg/administration.svg";

import Button from "../../components/button/button";
import Hero from "../../components/hero/hero";
import HeaderBig from "../../components/header-big/header-big";
import CallFooter from "../../components/call-footer/call-footer";
import StickyContainer from "../../components/sticky/sticky-container";
import PortfolioList from "../../components/portfolio-list/portfolio-list";
import { Portfolio, PortfolioNode } from "../../utils/portfolio";
import Section from "../../components/air-section/air-section";
import BoxList from "../../components/box-list/box-list";
import ScrollButton from "../../components/scroll-button/scroll-button";

const AplikacjeMobilnePage = (params: PageParams) => {
  const language = params.pageContext.language;
  const scrollRef = useRef();
  const experienceItems = [
    {
      name: "Spotkanie / AGILE / SCRUM",
      description:
        "Wysłuchamy wszystkich Twoich pomysłów, opowiemy o&nbsp;możliwościach technicznych i&nbsp;najkorzystniejszych rozwiązaniach. Zaplanyjemy medodologię pracy zgodną z&nbsp;modelami AGILE i&nbsp;SCRUM. ",
    },
    {
      name: "Wycena / Umowa / Specyfikacja",
      description:
        "Po zapoznaniu się z&nbsp;oczekiwaniami, przemyślimy rozwiązania dla Twojej aplikacji mobilnej, biorąc pod uwagę najlepsze praktyki i&nbsp;optymalny budżet realizacji. Przedstawimy doprecyzowaną ofertę, specyfikację projektu i&nbsp;prostą umowę.",
    },
    {
      name: "Makiety / Prototyp / Projekt graficzny",
      description:
        "Graficy przygotują makiety funkcjonalne i&nbsp;zaprojektują szatę graficzną aplikacji mobilnej używając najlepszych narzędzi (Sketch, PSD, Illustrator). Stworzone przez nas, klikalne prototypy, zobrazują rozmieszczenie treści, układ nawigacji i&nbsp;główne funkcjonalności.",
    },
    {
      name: "Programowanie / Modułowe wydania",
      description:
        "Po akceptacji makiet aplikacji, nasi programiści zaprogramują aplikację zgodną z&nbsp;przygotowanym projektem graficznym. Zadbają o&nbsp;prawidłowe działanie wszystkich funkcjonalności ujętych w&nbsp;specyfikacji.",
    },
    {
      name: "Testowanie / Wydanie / GooglePlay & App Store",
      description:
        "Przygotujemy testy i&nbsp;bezproblemowe wydania aplikacji do sklepów Google i&nbsp;Apple Store. Zainstalujemy na wskazanych serwerach oprogramowanie umożliwiające zarządzanie aplikacją (CMS).",
    },
    {
      name: "Opieka / Administracja / Rozwój",
      description:
        "Po przygotowaniu aplikacji mobilnej, nasi klienci mogą liczyć na stałą opiekę, administrację oraz rozwój nowych funkcjonalności.",
    },
  ];

  const data = useStaticQuery(
    graphql`
      {
        mobile_app: file(
          relativePath: { eq: "aplikacje-mobilne/aplikacje-mobilne.png" }
        ) {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        multi_platform: file(
          relativePath: {
            eq: "aplikacje-mobilne/aplikacje-multi-platformowe.png"
          }
        ) {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        screen1: file(relativePath: { eq: "aplikacje-mobilne/screen1.png" }) {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        screen2: file(relativePath: { eq: "aplikacje-mobilne/screen2.png" }) {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        screen3: file(relativePath: { eq: "aplikacje-mobilne/screen3.png" }) {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        aplikacja_zielone_pogotowie: file(
          relativePath: {
            eq: "aplikacje-mobilne/aplikacja_mobilna_zielone_pogotowie.png"
          }
        ) {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        portfolio: allFile(
          filter: {
            sourceInstanceName: { eq: "portfolio" }
            extension: { eq: "mdx" }
          }
        ) {
          group(field: relativeDirectory) {
            nodes {
              id
              sourceInstanceName
              relativeDirectory
              relativePath
              childMdx {
                frontmatter {
                  hero_color
                  color
                  section_bg
                  category
                  slug
                  title
                  language
                  description
                  short_description
                  created
                  thumbnail {
                    childImageSharp {
                      fluid(maxWidth: 900, maxHeight: 600) {
                        base64
                        src
                        aspectRatio
                        sizes
                        src
                        srcSet
                      }
                    }
                  }
                  gallery_alt
                  gallery {
                    childImageSharp {
                      fluid(maxWidth: 1600) {
                        base64
                        src
                        aspectRatio
                        src
                        srcSet
                        sizes
                      }
                    }
                  }
                }
                body
              }
            }
          }
        }
      }
    `
  );
  const itemSlugs = ["zielone-pogotowie-app", "simplic-bp", "carnet"];
  const items = Portfolio.getNodesByLanguage("pl", data.portfolio.group)
    .filter((node: PortfolioNode) => {
      return itemSlugs.indexOf(node.childMdx.frontmatter.slug) >= 0;
    })
    .map((node: PortfolioNode) => {
      return node.childMdx.frontmatter;
    });

  return (
    <>
      <SEO
        title="Aplikacje Mobilne"
        description="Tworzymy profesjonalne aplikacje mobilne dla firm na platformy Android i iOS •Aplikacje Mobilne ☎ +48 665 035 451"
        lang={language}
      />
      <Hero>
        <div className="container-xs header-padding">
          <h1>Aplikacje mobilne</h1>
          <div className="header-subline">
            <h2 className="subtitle">
              Tworzymy aplikacje mobilne na platformy iOS oraz Android
            </h2>
          </div>
          <h4>
            Realizując usługi związane z&nbsp;przygotowaniem aplikacji mobilnych
            dbamy o&nbsp;projektowanie, dewelopment oraz testowanie produktu.
            Klienci doceniają estetykę i&nbsp;intuicyjność wykonanych
            interfejsów oraz prawidłowe działanie aplikacji na wszystkich
            urządzeniach mobilnych.
          </h4>
        </div>
        <ScrollButton
          style={{
            position: "absolute",
            left: "50%",
            bottom: "5%",
            transform: "translateX(-50%)",
          }}
          to={scrollRef}
        />
      </Hero>
      <Section className="container" ref={scrollRef}>
        <HeaderBig
          tag="h2"
          span="Co zyskasz"
          headerText="Dlaczego warto zaprojektować aplikację mobilną w&nbsp;naszej agencji"
        />
        <p>
          <strong>
            Jesteśmy doświadczeni w&nbsp;tworzeniu innowacyjnych rozwiązań na
            urządzenia mobilne
          </strong>{" "}
          (telefony, tablety, Smart TV). Technologia z&nbsp;której korzystamy,
          pozwala nam wdrażać aplikacje mobilne szybciej i&nbsp;taniej niż inne
          firmy deweloperskie. Oferujemy szeroki wachlarz usług o&nbsp;dowolnym
          stopniu skomplikowania bez uszczerbku jakości.
        </p>
        <p>
          Potrafimy{" "}
          <strong>używać dedykowanych funkcji urządzeń mobilnych</strong> (
          <strong>Aparat</strong>, <strong>lokalizator GPS</strong>,{" "}
          <strong>czytnik NFC</strong>, <strong>powiadomienia push</strong>)
          i&nbsp;wykorzystać je w&nbsp;aplikacji mobilnej, w&nbsp;formie
          dedykowanej platformy bazodanowej dla wsparcia biznesu, aplikacji
          zakupowej lub społecznościowej. Tworzymy oprogramowanie dla startupów,
          średnich firm oraz korporacji. Kontrolujemy koszty wdrożeniowe (
          <strong>Aplikacja Mobilna w&nbsp;formie MVP</strong> oraz jej
          późniejszy rozwój nie są nam straszne).
        </p>
      </Section>
      <Section>
        <StickyContainer className="r :a-c :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky className="">
            <Img
              fluid={data.mobile_app.childImageSharp.fluid}
              className="image-test"
              alt="Grafika reklamująca Aplikacje Mobilne jako jedną z&nbsp;usług firmy Aplinet"
              title="MobileApp by Aplinet - tworzymy aplikacje mobilne"
            />
          </div>
          <div className="container overflow-hiden">
            <HeaderBig
              tag="h2"
              span="01</br>Doświadczenie"
              headerText="Znajdziemy rozwiązanie mobilne Twojego problemu"
            />
            <p>
              W&nbsp;trakcie jednego spotkania, zidentyfikujemy kluczowe procesy
              i&nbsp;poznamy kontekst biznesowy. Przeprowadzimy analizę wymagań
              funkcjonalnych, zdefiniujemy ścieżkę doświadczeń użytkownika (
              <strong>User Stories</strong>), określimy cele i&nbsp;funkcje
              aplikacji. Od początku współpracy{" "}
              <strong>
                stawiamy na dobrą komunikację i&nbsp;prawidłowe prowadzenie
                projektu
              </strong>
              .
            </p>
            <p>
              Przeprowadzamy <strong>warsztaty UX i&nbsp;UI Design</strong> dla
              klientów, którzy rozpoczynają dopiero swoją przygodę MobileApp.
              Jest to świetne rozwiązanie na przygotowanie zakresu produktu,
              który sprosta wymaganiom użytkowników i&nbsp;pozwoli na{" "}
              <strong>skalowalność aplikacji mobilnej</strong>{" "}
              w&nbsp;przyszłości.
            </p>
            <p>
              <strong>Zapewniamy realizację aplikacji mobilnych:</strong>
            </p>
            <ul>
              <li>
                prowadzoną w&nbsp;modelu <strong>Scrum</strong>,{" "}
                <strong>Agile</strong>,
              </li>
              <li>
                opartą o&nbsp;prawidłowy <strong>UX i&nbsp;UI Design</strong>,
              </li>
              <li>
                w&nbsp;optymalnym czasie i&nbsp;budżecie (
                <strong>MVP/Spine/Alpha...</strong>).
              </li>
            </ul>
          </div>
        </StickyContainer>
      </Section>
      <Section>
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky>
            <Img
              fluid={data.multi_platform.childImageSharp.fluid}
              className="image-test"
              alt="Grafika przedstawia możliwość realizacji oproramowania dostępnego na wszystkich systemach operacyjnych"
              title="Aplikacje mobilne na platformy Android i&nbsp;iOS"
            />
          </div>
          <div className="container flex-sm:-1">
            <HeaderBig
              tag="h2"
              span="02</br>Uniwersalność"
              headerText="Aplikacje Cross Platform, PWA, Andrioid, iOS - dostosujemy technologię do wymagań a&nbsp;nie odwrotnie"
            />
            <p>
              Od wielu lat tworzymy aplikacje mobilne wykorzystując większość
              dostępnych technologii.{" "}
              <strong>
                Doświadczenie w&nbsp;rozwiązaniach natywnych (native)
              </strong>
              , <strong>w&nbsp;pełni natywnych (full native)</strong> lub{" "}
              <strong>hybrydowych (hybrid)</strong>, pozwalają nam realizować
              projekty o&nbsp;różnym stopniu skomplikowania w&nbsp;rozsądnym
              terminie.
            </p>
            <p>
              <strong>PWA</strong>, <strong>Flutter</strong>,{" "}
              <strong>React Native</strong> i&nbsp;<strong>NativeScript</strong>{" "}
              umożliwiają działanie aplikacji natywnie oraz wystawienie jej
              w&nbsp;sklepach. Możliwości są praktycznie nieograniczone. Możemy
              korzystać z&nbsp;<strong>API</strong>, wysyłać{" "}
              <strong>powiadomienia push</strong> do użytkowników,{" "}
              <strong>map</strong>, używać modułu <strong>NFC</strong>,{" "}
              <strong>GPS</strong>, aktualizować treść aplikacji (
              <strong>CMS</strong>) oraz wdrożyć inne wymagania używając funkcji
              urządzenia.
            </p>
            <div className="technology-wrapper r :w :a-c :c-6 :c-3:sm+">
              <div className="item">
                <NativeScript />
              </div>
              <div className="item">
                <ReactLogo />
              </div>
              <div className="item">
                <Ionic />
              </div>
              <div className="item">
                <LogoElectron />
              </div>
              <div className="item">
                <Flutter />
              </div>
              <div className="item">
                <NodeJs />
              </div>
            </div>
          </div>
        </StickyContainer>
      </Section>
      <Section>
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky className="">
            <div
              className="container r :c-4 :p"
              style={{ marginBottom: "1em" }}
            >
              <div style={{ padding: "0 0.5em" }}>
                <Img
                  fluid={data.screen1.childImageSharp.fluid}
                  className="image-test"
                  alt="Bridge"
                  title="Bridge"
                />
              </div>
              <div style={{ padding: "0 0.5em" }}>
                <Img
                  fluid={data.screen2.childImageSharp.fluid}
                  className="image-test"
                  alt="Bridge"
                  title="Bridge"
                />
              </div>
              <div style={{ padding: "0 0.5em" }}>
                <Img
                  fluid={data.screen3.childImageSharp.fluid}
                  className="image-test"
                  alt="Bridge"
                  title="Bridge"
                />
              </div>
            </div>
            <div className="container r :c-6 :a-ce">
              <a
                href="https://play.google.com/store/apps/developer?id=Aplinet+Apps"
                alt="Aplikacja Zielone Pogotowie w sklepie Google Play"
                rel="noreferrer noopener"
                target="_blank"
                style={{ maxWidth: "48%" }}
              >
                <GooglePlaySVG width="100%"></GooglePlaySVG>
              </a>
              <a
                href="https://apps.apple.com/us/developer/konrad-frysiak/id1508284249"
                alt="Aplikacja Zielone Pogotowie w sklepie App Store"
                rel="noreferrer noopener"
                target="_blank"
                style={{ maxWidth: "48%" }}
              >
                <AppStoreSVG width="100%"></AppStoreSVG>
              </a>
            </div>
          </div>
          <div className="container">
            <HeaderBig
              tag="h2"
              span="03 Publikacje"
              headerText="Nasze aplikacje odnoszą sukces nie tylko w&nbsp;sklepach Google Play i&nbsp;App Store"
            />
            <p>
              Według najnowszych danych w&nbsp;<strong>AppStore</strong> i&nbsp;
              <strong>Google Play</strong> znajduje się łącznie ponad 5&nbsp;mln
              aplikacji. Z&nbsp;roku na rok dodawane są nowe funkcje
              a&nbsp;deweloperzy próbują udoskonalać swoje programy
              i&nbsp;wychodzić naprzeciw wymaganiom klientów. Aplikacje
              rozwijają się w&nbsp;zastraszającym tempie, zwiększa się też
              konkurencja.
            </p>
            <p>
              Systematyczne rozwijami naszą wiedzę i&nbsp;doświadczenie{" "}
              <strong>w&nbsp;marketingu aplikacji mobilnych</strong>, żeby{" "}
              <strong>promowacja aplikacji</strong> była skuteczna
              i&nbsp;systematycznie zwiększała ilość użytkowników. Prowadzimy{" "}
              <strong>statystyki</strong>, <strong>raporty</strong> oraz
              zdecydowane reagujemy na zmieniającą się rzeczywistość.
              Analizujemy pobieranie, <strong>aktywność</strong>,{" "}
              <strong>wizyty użytkowników</strong>, czas spędzany
              w&nbsp;aplikacji, <strong>oceny</strong> oraz{" "}
              <strong>przychody</strong>.
            </p>
            <p>
              Nasze aplikacje zostały zauważone w&nbsp;magazynach{" "}
              <strong>Twój Styl</strong>, <strong>Komputronik</strong> oraz
              otrzymały wyróżnienie <strong>Gazety Wyborczej</strong> na gali{" "}
              <strong>Złote Spinacze.</strong>
            </p>
          </div>
        </StickyContainer>
      </Section>
      <Section>
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky className="container">
            <Img
              fluid={data.aplikacja_zielone_pogotowie.childImageSharp.fluid}
              className="image-test"
              alt="Grafika przedstawia mockup aplikacji mobilnej Zielone Pogotowie"
              title="Aplikacja Mobilna Zielone Pogotowie"
            />
          </div>
          <div className="container flex-sm:-1">
            <HeaderBig
              tag="h2"
              span="04 Ciekawe rozwiązania"
              headerText="Znamy możliwości jakie dają aplikacje mobilne i&nbsp;potrafimy je wykorzystać"
            />
            <p>
              Użytkownicy telefonów z&nbsp;systemem iOS oraz Android, większość
              czasu spędzają używając zainstalowanych aplikacji (Ponad 80%).
              Tylko 16% czasu poświęcają na przeglądanie informacji za
              pośrednictwem przeglądarki internetowej. Generalnie lubimy
              telefony komórkowe i&nbsp;wykorzystujemy je najczęściej.
            </p>
            <p>
              Dzięki zainstalowanym aplikacjom użytkownicy uzyskują dostęp do
              szybkiego i&nbsp;bezproblemowe działania, stabilności oraz
              różnorodność opcji takich jak GPS lub NFC.
            </p>
            <h4>Nasza aplikacje mobilne zapewniają:</h4>
            <ul>
              <li>
                interakcję z&nbsp;użytkownikami, komunikatory, push
                notyfication,
              </li>
              <li>płatności,</li>
              <li>
                wykorzystanie funkcji telefonu (aparat, kamera, nawigacja,
                odtwarzacz muzyki, komunikator).
              </li>
            </ul>
          </div>
        </StickyContainer>
      </Section>
      <Section className="">
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky className="container">
            <img
              src={Administration}
              alt="Administracja aplilacjami mobilnymi"
            />
          </div>
          <div className="container overflow-hiden">
            <HeaderBig
              tag="h2"
              span="05 Administracja"
              headerText="Zapewniamy hosting, utrzymanie oraz aktualizacje i&nbsp;rozwój aplikacji mobilnych"
            />
            <p>
              <strong>Uruchomienie aplikacji</strong> lub jej{" "}
              <strong>opublikowanie</strong> w&nbsp;sklepie nie oznacza
              zakończenia współpracy. Nasze usługi obejmują również opiekę nad
              produktem oraz jego rozwój jeżeli zaistnieje taka potrzeba.
            </p>
            <p>
              Czuwamy nad prawidłowym działaniem aplikacji oraz dostępnością dla
              użytkowników przez 24h/dobę. Monitorujemy raporty oraz sprawnie
              reagujemy na zgłaszane błędy aby użytkownicy aplikacji mogli
              korzystać z&nbsp;niej bez przerw.
            </p>
            <ul>
              <li>
                przeprowadzimy{" "}
                <strong>testy wydajnościowe i&nbsp;jakościowe,</strong>
              </li>
              <li>
                zapewnimy <strong>dostępność systemu</strong> na wysokim 99%
                poziomie,
              </li>
              <li>
                otrzymasz pełne{" "}
                <strong>
                  wsparcie w&nbsp;zakresie utrzymania i&nbsp;rozwoju aplikacji
                  mobilnych
                </strong>
                .
              </li>
            </ul>
          </div>
        </StickyContainer>
      </Section>
      <Section className="">
        <div className="align-center">
          <Button color="primary" type="inversed" to="/kontakt" language="pl">
            Darmowa wycena aplikacji mobilnej
          </Button>
        </div>
      </Section>
      <Section backgroundColor="#fbfbfb">
        <div className="conteiner align-center">
          <h2>Zakres naszych kompetencji</h2>
        </div>
        <div
          className="container r :w :p :c-12 :c-6:xs :c-4:xs+"
          style={{
            paddingTop: "1em",
            paddingBottom: "1em",
          }}
        >
          <div className="align-center c-12 c-4:xs+">
            <div className="r :c-4 :a-ca">
              <div style={{ maxWidth: "150px", margin: "auto" }}>
                <AppleLogo />
              </div>
              <div style={{ maxWidth: "150px", margin: "auto" }}>
                <AndroidRobot />
              </div>
            </div>
          </div>
          <div>
            <h4 style={{ marginBottom: "0.5em" }}>
              Projektowanie tworzenie i&nbsp;rozwój aplikacji Cross Platform,
              Hybrydowych i&nbsp;PWA
            </h4>
            <p>
              Pomagamy budować i&nbsp;wdrażać aplikacje mobilne Cross Platform,
              PWA. Pomożemy Twojej firmie prototypować, zaprojektować,
              programować oraz rozwijać aplikacje mobilną działającą na
              wszystkich systemach.
            </p>
          </div>
          <div>
            <h4 style={{ marginBottom: "0.5em" }}>Doświadczenie</h4>
            <ul>
              <li>Flutter</li>
              <li>NativeScript</li>
              <li>React Native</li>
              <li>Ionic</li>
              <li>Cordova</li>
              <li>PWA</li>
            </ul>
          </div>
        </div>
        <div
          className="container r :w :p :c-12 :c-6:xs :c-4:xs+"
          style={{
            backgroundColor: "#f5f5f5",
            paddingTop: "1em",
            paddingBottom: "1em",
          }}
        >
          <div className="align-center c-12 c-4:xs+">
            <div style={{ maxWidth: "150px", margin: "auto" }}>
              <AndroidRobot />
            </div>
          </div>
          <div>
            <h4 style={{ marginBottom: "0.5em" }}>
              Projektowanie tworzenie i&nbsp;rozwój aplikacji Android
            </h4>
            <p>
              Pomagamy budować i&nbsp;wdrażać aplikacje mobilne na systemy iOS.
              Pomożemy Twojej firmie prototypować, zaprojektować, programować
              oraz rozwijać aplikacje iOS najwyższej jakości.
            </p>
          </div>
          <div>
            <h4 style={{ marginBottom: "0.5em" }}>Doświadczenie</h4>
            <ul>
              <li>Android NDK</li>
              <li>Programowanie Java / Kotlin / C++</li>
            </ul>
          </div>
        </div>
        <div
          className="container r :w :p :c-12 :c-6:xs :c-4:xs+"
          style={{
            paddingTop: "1em",
            paddingBottom: "1em",
          }}
        >
          <div className="align-center c-12 c-4:xs+">
            <div style={{ maxWidth: "150px", margin: "auto" }}>
              <AppleLogo />
            </div>
          </div>
          <div>
            <h4 style={{ marginBottom: "0.5em" }}>
              Projektowanie tworzenie i&nbsp;rozwój aplikacji iOS
            </h4>
            <p>
              Pomagamy budować i&nbsp;wdrażać aplikacje mobilne na systemy iOS.
              Pomożemy Twojej firmie prototypować, zaprojektować, programować
              oraz rozwijać aplikacje iOS najwyższej jakości.
            </p>
          </div>
          <div>
            <h4 style={{ marginBottom: "0.5em" }}>Doświadczenie</h4>
            <ul>
              <li>iOS UI/iOS SDK</li>
              <li>Programowanie Swift / Objective-C</li>
            </ul>
          </div>
        </div>
      </Section>
      <Section className="container">
        <HeaderBig
          tag="h2"
          span="Aplikacje mobilne"
          headerText="Kilka naszych realizacji"
        />
        <PortfolioList items={items}></PortfolioList>
        <div className="align-center">
          <Button
            color="primary"
            type="inversed"
            to="/realizacje"
            language="pl"
          >
            Zobacz wszystkie realizacje
          </Button>
        </div>
      </Section>
      <Section backgroundColor="#000" className="container">
        <HeaderBig
          tag="h2"
          span="Profity aplikacji</br>mobilnych"
          spanColor="#484848"
          headerText="Po co tworzyć profesjonalną aplikację mobilną"
          color="#50817b"
        />
        <BoxList
          boxItems={[
            {
              title: `Wygodna komunikacja`,
              span: "01",
              description:
                "Aplikacje mobilne umożliwiają użytkownikom wygodną wymianę informacji za pomocą telefonu lub tabletu. Pozwalają na komunikację zespołu.",
            },
            {
              title: `Zwiększają zasięg`,
              span: "02",
              description:
                "Dzięki popularyzacji urządzeń mobilnych coraz więcej użytkowników korzysta z&nbsp;funkcjonalności aplikacji na smartfonach i&nbsp;tabletach.",
            },
            {
              title: `Optymalizują zadania`,
              span: "03",
              description:
                "Umożliwiają wykorzystanie funkcji takich Aparatu, Kamery, NFC, GPS. Pozwalają na śledzenie zmian w&nbsp;czasie rzeczywistym.",
            },
          ]}
        />
      </Section>
      <Section>
        <StickyContainer className="r :a-t :c-6 :c-12:xs :c-12:xxs :w">
          <div data-sticky className="container">
            <HeaderBig
              tag="h2"
              span="Przegieg współpracy"
              headerText="Etapy tworzenia aplikacji mobilnej"
            />
            <p>
              Przed złożeniem zapytania ofertowego, postaraj się odpowiedzieć na
              pytanie "po jaką usługę przychodzisz do agencji". Zastanów się,
              jakiego zakresu kompetencji potrzebujesz, w&nbsp;jakim obszarze
              mamy wspierać Twój pomysł? Podsumuj swoje oczekiwania
              i&nbsp;przygotuj pytania.
            </p>
            <p>
              Aby ułatwić to zadanie, przygotowaliśmy etapy prawidłowo
              zaplanowanej współpracy przy tworzeniu aplikacji mobilnej.
            </p>
            <div style={{ textAlign: "center" }}>
              <Button
                color="primary"
                type="default"
                to="/kontakt"
                language="pl"
              >
                Wyceń swóją aplikację
              </Button>
            </div>
          </div>
          <ul className="experience count container">
            {experienceItems.map((item, index) => {
              return (
                <InView key={index} rootMargin="-49% 0px -49% 0px">
                  {({ inView, ref, entry }) => (
                    <li ref={ref} className={inView ? "active" : ""}>
                      <div>
                        <h3
                          dangerouslySetInnerHTML={{ __html: item.name }}
                        ></h3>
                        <p
                          dangerouslySetInnerHTML={
                            item.description && { __html: item.description }
                          }
                        ></p>
                      </div>
                    </li>
                  )}
                </InView>
              );
            })}
          </ul>
        </StickyContainer>
      </Section>
      <CallFooter />
    </>
  );
};

export default AplikacjeMobilnePage;
